aside {
  display: flex;
  flex: 0.1 1;
  width: 10rem;
  height: 100%; }
  aside .menu {
    border: none !important; }
    aside .menu .item {
      border-left: 3px solid transparent !important; }
      aside .menu .item .menu .item {
        padding: 0.8em !important;
        padding-left: 1.5em !important; }
      aside .menu .item.active {
        border-right-style: none !important;
        border-left-color: #E74B97 !important; }
