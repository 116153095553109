.main-header {
  position: sticky;
  top: 0;
  z-index: 100;
  display: flex;
  padding: 10px 16px;
  width: 100%;
  background-color: #fff;
  border-bottom: 1px solid #e5e5e4; }
  .main-header .logo {
    flex: 0.1;
    align-self: center; }
    .main-header .logo .image {
      max-height: 20px; }
  .main-header .user {
    flex: 1;
    text-align: right;
    align-self: center; }
    .main-header .user .avatar {
      border: 1px solid #b3b3b3; }
