.layout {
  flex: 1;
  display: flex;
  height: calc(100% - 60px); }
  .layout .component {
    width: 100%;
    padding: 1rem;
    background-color: #fff;
    overflow-x: auto; }
    .layout .component > .segment > .header.title {
      border-bottom: inherit;
      font-size: 14px !important;
      text-transform: uppercase;
      letter-spacing: 1px; }
  .layout.hasLogin {
    height: 100%;
    margin-top: 0; }
    .layout.hasLogin .component {
      margin: 0; }

.loader {
  height: 100%; }
  .loader .ui.segment {
    height: 100%; }

.dimmed.dimmable > .ui.animating.dimmer, .dimmed.dimmable > .ui.visible.dimmer, .ui.active.dimmer {
  display: flex !important; }
