html, body {
  min-height: 100vh;
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  background: #F1F1F4;
}

#splend {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
